const config = {
    // customisation
    env: 'agencedelenergie',
    color: '#d1dd74',
    rgbPDF: {
        r: 0.184,
        g: 0.361,
        b: 0.475
    },
    header: {
        backgroundPosition: '70% 60%',
        logoContainerPadding: 50,
        logo: {
            height: 100,
            width: 100,
            backgroundColor: 'white'
        },
        title: ''
    },
    name: 'SARL ADELEC.',
    custom: {
        // ajouter une clé pour ajouter une ligne
        1: '4 place Emile Digeon',
        2: '11100 Narbonne',
        3: '04 68 58 46 14'
    },
    mail: 'contact@agence-de-lenergie.com',
    privacy: '',
    legal: '',
    modalType: 'achille', // 'achille' or ''
    hotline: '', // '09 00 00 00 00' or ''
    hotjar: {
        hjid: 0,
        hjsv: 0
    },
    googleAnalyticId: '',

    apiGoogle: {
        key: 'AIzaSyCRMyPFYFvqYqWSGAcoWBDNSSzy7ZliNxU',
        libraries: ['geometry', 'places'],
    },
    // internal urls
    sendFormUrl: 'https://simulateur.agence-de-lenergie.com/sendPDF',
    fetchBill: 'https://simulateur.agence-de-lenergie.com/estimated-invoice',
    fetchEstimation: 'https://simulateur.agence-de-lenergie.com/pre-calculate',

    // external urls
    achilleUrl: 'https://agence-de-lenergie.fhe-france.com',
};

export default config;
